
					@import './src/sass/variables';
					@import './src/sass/mixins';
				

















































































.block-inner {
	display: flex;
	flex-flow: row wrap;

	&.align-center {
		justify-content: center;
	}

	&.align-right {
		justify-content: flex-end;
	}

	& > div {
		flex: 1;
	}
}

.pdf-footer {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
}

.button {
	margin: 10px;
}
